import DashboardLayout from "@/pages/layouts/DashboardLayout";

let categoryRoutes = {
  path: '/categories',
  component: DashboardLayout,
  redirect: '/categories/list',
  children: [
    {
      path: 'list/ar',
      name: 'Category List',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/categories/CategoryList'),
      meta: {
        permissionsCodes: ['ar/categories/index', 'restricted_own/ar/categories/index'],
      }
    },
    {
      path: 'create/ar',
      name: 'Add Category',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/categories/CategoryForm'),
      meta: {
        permissionsCodes: ['ar/categories/create'],
      }
    },
    {
      path: 'edit/:id/ar',
      name: 'Edit Category',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/categories/CategoryForm'),
      meta: {
        permissionsCodes: ['ar/categories/update', 'restricted_own/ar/categories/update'],
      }
    }
  ]
}

export default categoryRoutes;
