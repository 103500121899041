import DashboardLayout from "@/pages/layouts/DashboardLayout";

let notificationsRoutes = {
  path: '/notifications',
  component: DashboardLayout,
  redirect: '/notifications/list',
  children: [

    {
      path: 'ar',
      name: 'Notifications List',
      component: () => import(/* webpackChunkName: "branches" */  '@/pages/notifications/notificationList'),
      meta: {
        permissionsCodes: ['ar/notifications/list'],
      }
    },
    {
      path: 'create/ar',
      name: 'Add Notification',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/notifications/notificationForm'),
      meta: {
        permissionsCodes: ['ar/notifications/create'],
      }
    },

  ]
}

export default notificationsRoutes;
