var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{'nav-open': _vm.$sidebar.showSidebar}},[_c('notifications'),_c('side-bar',{scopedSlots:_vm._u([{key:"links",fn:function(props){return [_c('sidebar-item',{attrs:{"link":{name: 'Dashboard', icon: 'nc-icon nc-chart-pie-35', path: '/overview'}}}),(_vm.$store.getters['auth/haveOneOfPermissions'](['ar/live-news/index','restricted_own/ar/live-news/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Live News', icon: 'nc-icon nc-button-play', path: '/live-news/list/ar'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                                          (['ar/categories/index', 'restricted_own/ar/categories/index',
                                          'ar/tags/index', 'restricted_own/ar/tags/index',
                                          'ar/authors/index', 'restricted_own/ar/authors/index',
                                          'ar/sources/index', 'restricted_own/ar/sources/index',
                                          'ar/news/index', 'restricted_own/ar/news/index',
                                          'ar/classifications/index', 'restricted_own/ar/classifications/index']))?_c('sidebar-item',{attrs:{"link":{name: 'News', icon: 'nc-icon nc-paper-2'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['ar/news/index','restricted_own/ar/news/index']))?_c('sidebar-item',{attrs:{"link":{name: 'News', path: '/news/list/ar'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['ar/categories/index','restricted_own/ar/categories/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Categories', path: '/categories/list/ar'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['ar/tags/index','restricted_own/ar/tags/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Tags', path: '/tags/list/ar'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['ar/authors/index','restricted_own/ar/authors/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Authors', path: '/authors/list/ar'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['ar/sources/index','restricted_own/ar/sources/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Sources', path: '/sources/list/ar'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['ar/classifications/index','restricted_own/ar/classifications/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Classification', path: '/classifications/list/ar'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['ar/interviews/index', 'restricted_own/ar/interviews/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Interviews List', icon: 'nc-icon nc-audio-92', path: '/interviews/list/ar'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                                          (['ar/visual-editors/home-page']))?_c('sidebar-item',{attrs:{"link":{name: 'Visual Editors', icon: 'nc-icon nc-grid-45'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['ar/visual-editors/home-page']))?_c('sidebar-item',{attrs:{"link":{name: 'Home Page', path: '/visual-editors/home-page/ar'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['newsletter/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Newsletter Subscribers', icon: 'nc-icon nc-email-83', path: '/newsletter'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['contact-us/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Contact Messages', icon: 'nc-icon nc-support-17', path: '/contact-us'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['ar/polls/index', 'restricted_own/ar/polls/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Poll List', icon: 'nc-icon nc-attach-87', path: '/polls/list/ar'}}}):_vm._e(),(false)?_c('sidebar-item',{attrs:{"link":{name: 'Notifications', icon: 'nc-icon nc-notification-70', path: '/notifications/ar'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                                          (['ar/pages/index', 'restricted_own/ar/pages/index',
                                           'ar/content-pages/create',
                                           'ar/redirection-pages/create',
                                           'ar/branches/index', '/restricted_own/ar/branches/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Pages', icon: 'nc-icon nc-single-copy-04'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['ar/pages/index','restricted_own/ar/pages/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Existing Pages', path: '/pages/list/ar'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['ar/content-pages/create']))?_c('sidebar-item',{attrs:{"link":{name: 'Add Content Page', path: '/content-pages/create/ar'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['ar/redirection-pages/create']))?_c('sidebar-item',{attrs:{"link":{name: 'Add Redirection Page', path: '/redirection-pages/create/ar'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['ar/branches/index','restricted_own/ar/branches/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Branches', path: '/branches/list/ar'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                     (['ar/panel-groups/index','ar/panels/index',
                     'restricted_own/ar/panel-groups/index','restricted_own/ar/panels/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Panels And Banners', icon: 'nc-icon nc-album-2'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['ar/panels/index','restricted_own/ar/panels/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Panels', path: '/panels/list/ar'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['ar/panel-groups/index','restricted_own/ar/panel-groups/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Groups', path: '/panel-groups/list/ar'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['ar/settings/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Settings', icon: 'nc-icon nc-settings-gear-64', path: '/settings/list/ar'}}}):_vm._e(),(_vm.$store.getters['auth/checkAccess']('roles/index')
                          // || $store.getters['auth/checkAccess']('permissions/index')
                          || _vm.$store.getters['auth/checkAccess']('users/index')
                          || _vm.$store.getters['auth/checkAccess']('users/get-deleted-users'))?_c('sidebar-item',{attrs:{"link":{name: 'Access Management', icon: 'nc-icon nc-circle-09'}}},[(_vm.$store.getters['auth/checkAccess']('roles/index'))?_c('sidebar-item',{attrs:{"link":{name: 'Roles', path: '/roles/list'}}}):_vm._e(),(false)?_c('sidebar-item',{attrs:{"link":{name: 'Permissions', path: '/permissions/list'}}}):_vm._e(),(_vm.$store.getters['auth/checkAccess']('users/index'))?_c('sidebar-item',{attrs:{"link":{name: 'Users', path: '/users/list'}}}):_vm._e(),(_vm.$store.getters['auth/checkAccess']('users/get-deleted-users'))?_c('sidebar-item',{attrs:{"link":{name: 'Deleted Users', path: '/users/DeletedUsersList'}}}):_vm._e()],1):_vm._e()]}}])},[_c('user-menu'),_c('mobile-menu')],1),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('dashboard-content',{nativeOn:{"click":function($event){return _vm.toggleSidebar($event)}}}),_c('content-footer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }