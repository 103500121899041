import DashboardLayout from "@/pages/layouts/DashboardLayout";

let liveNewsArRoutes = {
  path: '/live-news',
  component: DashboardLayout,
  redirect: '/live-news/list',
  children: [
    {
      path: 'list/ar',
      name: 'Live News List',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/liveNews/LiveNewsList'),
      meta: {
        permissionsCodes: ['ar/live-news/index', 'restricted_own/ar/live-news/index'],
      }
    },
    {
      path: 'create/ar',
      name: 'Add Live News',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/liveNews/LiveNewsForm'),
      meta: {
        permissionsCodes: ['ar/live-news/create'],
      }
    },
    {
      path: 'edit/:id/ar',
      name: 'Edit Live News',
      component: () => import(/* webpackChunkName: "panels" */  '@/pages/news/liveNews/LiveNewsForm'),
      meta: {
        permissionsCodes: ['ar/live-news/update', 'restricted_own/ar/live-news/update'],
      }
    }
  ]
}

export default liveNewsArRoutes;
