<template>

  <div class="row" ref="userForm">

    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-xs-6 col-md-6 col-sm-12">

                <ValidationProvider
                  vid="first_name"
                  rules="required"
                  name="First Name"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="First Name* "
                            name="first_name"
                            fou
                            v-model="formData.first_name">
                  </fg-input>
                </ValidationProvider>

                <ValidationProvider
                  vid="last_name"
                  rules="required"
                  name="Last Name"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Last Name *"
                            name="last_name"
                            fou
                            v-model="formData.last_name">
                  </fg-input>
                </ValidationProvider>

                <ValidationProvider
                  vid="email"
                  rules="required|email"
                  name="Email"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="email"
                            :error="failed ? errors[0]: null"
                            label="Email *"
                            name="email"
                            fou
                            v-model="formData.email">
                  </fg-input>
                </ValidationProvider>

                <ValidationProvider
                  vid="mobile"
                  rules="required"
                  name="Mobile"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="mobile"
                            :error="failed ? errors[0]: null"
                            label="Mobile *"
                            name="mobile"
                            fou
                            v-model="formData.mobile">
                  </fg-input>
                </ValidationProvider>


              </div>
              <div class="col-xs-6 col-md-6 col-sm-12">
                <single-file-uploader-file-manager
                  :preview-width="'200px'"
                  :preview-height="'200px'"
                  v-model="formData.profile_image"
                  :accepted-files="imagesExtensions">
                </single-file-uploader-file-manager>
              </div>
            </div>
          </div>

          <div class="card-footer text-center">

            <l-button :disabled="submitting" @click.prevent="handleSubmit(submit)" nativeType="submit"
                      type="info" wide>Update Profile
            </l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Select, Option} from 'element-ui';
import {Switch as LSwitch} from '@/components'
import SingleFileUploaderFileManager from "@/components/FileManager/SingleFileUploaderFileManager";


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    LSwitch,
    SingleFileUploaderFileManager
  },

  data() {
    return {
      loader: {},

      formTitle: "UpdateProfile",
      submitting: false,
      imagesExtensions:this.getImagesExtensions(),
      formData: {
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
        profile_image: ""
      },

    };
  },

  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.userForm
    });
    this.getUser();
  },


  methods: {
    getUser() {
      this.axios.post("auth/me").then((response) => {
        this.formData = response.data;
        this.loader.hide();
      }).catch((error) => {
        console.log(error);
      })
    },

    submit() {
      this.submitting = true;
      this.axios.post("auth/update-profile", this.formData).then((response) => {

        this.$store.dispatch("auth/updateUserData").then(() => {
          this.$notify({
            message: "Profile Updated Successfully",
            timeout: 1000,
            type: 'success'
          })
          this.$router.push("/");
        });

      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.submitting = false;
      });
    },

    handleImage(e) {
      this.formData.profile_image = e;
    }

  }
}
</script>

<style>
</style>
