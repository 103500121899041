import DashboardLayout from "@/pages/layouts/DashboardLayout";

let visualEditorRoutes = {
  path: '/visual-editors',
  component: DashboardLayout,
  redirect: '/visual-editors/home-page',
  children: [
    {
      path: 'home-page/ar',
      name: 'Home Visual Editor',
      component: () => import(/* webpackChunkName: "visual-editors" */  '@/pages/visualEditors/homePage/HomePageVisualEditor'),
      meta: {
        permissionsCodes: ['ar/visual-editors/home-page'],
      }
    },
  ]
}

export default visualEditorRoutes;
