<template>

  <div class="wrapper" :class="{'nav-open': $sidebar.showSidebar}">
    <notifications></notifications>

    <side-bar>

      <user-menu></user-menu>

      <mobile-menu></mobile-menu>

      <template slot-scope="props" slot="links">
        <sidebar-item :link="{name: 'Dashboard', icon: 'nc-icon nc-chart-pie-35', path: '/overview'}">
        </sidebar-item>
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/live-news/index','restricted_own/ar/live-news/index'])"
                      :link="{name: 'Live News', icon: 'nc-icon nc-button-play', path: '/live-news/list/ar'}"></sidebar-item>
        <sidebar-item  v-if="$store.getters['auth/haveOneOfPermissions']
                                            (['ar/categories/index', 'restricted_own/ar/categories/index',
                                            'ar/tags/index', 'restricted_own/ar/tags/index',
                                            'ar/authors/index', 'restricted_own/ar/authors/index',
                                            'ar/sources/index', 'restricted_own/ar/sources/index',
                                            'ar/news/index', 'restricted_own/ar/news/index',
                                            'ar/classifications/index', 'restricted_own/ar/classifications/index'])"
                       :link="{name: 'News', icon: 'nc-icon nc-paper-2'}">
          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/news/index','restricted_own/ar/news/index'])"
                        :link="{name: 'News', path: '/news/list/ar'}"></sidebar-item>
          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/categories/index','restricted_own/ar/categories/index'])"
                        :link="{name: 'Categories', path: '/categories/list/ar'}"></sidebar-item>
          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/tags/index','restricted_own/ar/tags/index'])"
                        :link="{name: 'Tags', path: '/tags/list/ar'}"></sidebar-item>
          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/authors/index','restricted_own/ar/authors/index'])"
                        :link="{name: 'Authors', path: '/authors/list/ar'}"></sidebar-item>
          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/sources/index','restricted_own/ar/sources/index'])"
                        :link="{name: 'Sources', path: '/sources/list/ar'}"></sidebar-item>
          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/classifications/index','restricted_own/ar/classifications/index'])"
                        :link="{name: 'Classification', path: '/classifications/list/ar'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/interviews/index', 'restricted_own/ar/interviews/index'])" :link="{name: 'Interviews List', icon: 'nc-icon nc-audio-92', path: '/interviews/list/ar'}">
        </sidebar-item>

        <sidebar-item  v-if="$store.getters['auth/haveOneOfPermissions']
                                            (['ar/visual-editors/home-page'])"
                       :link="{name: 'Visual Editors', icon: 'nc-icon nc-grid-45'}">
          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/visual-editors/home-page'])"
                        :link="{name: 'Home Page', path: '/visual-editors/home-page/ar'}"></sidebar-item>
        </sidebar-item>


        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['newsletter/index'])" :link="{name: 'Newsletter Subscribers', icon: 'nc-icon nc-email-83', path: '/newsletter'}">
        </sidebar-item>
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['contact-us/index'])" :link="{name: 'Contact Messages', icon: 'nc-icon nc-support-17', path: '/contact-us'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/polls/index', 'restricted_own/ar/polls/index'])" :link="{name: 'Poll List', icon: 'nc-icon nc-attach-87', path: '/polls/list/ar'}">
        </sidebar-item>
        <!--$store.getters['auth/haveOneOfPermissions'](['ar/notifications'])-->
        <sidebar-item   v-if="false"  :link="{name: 'Notifications', icon: 'nc-icon nc-notification-70', path: '/notifications/ar'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                                            (['ar/pages/index', 'restricted_own/ar/pages/index',
                                             'ar/content-pages/create',
                                             'ar/redirection-pages/create',
                                             'ar/branches/index', '/restricted_own/ar/branches/index'])"
                      :link="{name: 'Pages', icon: 'nc-icon nc-single-copy-04'}">

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/pages/index','restricted_own/ar/pages/index'])"
                        :link="{name: 'Existing Pages', path: '/pages/list/ar'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/content-pages/create'])"
                        :link="{name: 'Add Content Page', path: '/content-pages/create/ar'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/redirection-pages/create'])"
                        :link="{name: 'Add Redirection Page', path: '/redirection-pages/create/ar'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/branches/index','restricted_own/ar/branches/index'])"
                        :link="{name: 'Branches', path: '/branches/list/ar'}"></sidebar-item>

        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                       (['ar/panel-groups/index','ar/panels/index',
                       'restricted_own/ar/panel-groups/index','restricted_own/ar/panels/index'])"
                      :link="{name: 'Panels And Banners', icon: 'nc-icon nc-album-2'}">

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/panels/index','restricted_own/ar/panels/index'])"
                        :link="{name: 'Panels', path: '/panels/list/ar'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/panel-groups/index','restricted_own/ar/panel-groups/index'])"
                        :link="{name: 'Groups', path: '/panel-groups/list/ar'}"></sidebar-item>

        </sidebar-item>



        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['ar/settings/index'])"
                      :link="{name: 'Settings', icon: 'nc-icon nc-settings-gear-64', path: '/settings/list/ar'}">
        </sidebar-item>


        <!--</sidebar-item>-->

        <sidebar-item v-if="$store.getters['auth/checkAccess']('roles/index')
                            // || $store.getters['auth/checkAccess']('permissions/index')
                            || $store.getters['auth/checkAccess']('users/index')
                            || $store.getters['auth/checkAccess']('users/get-deleted-users')"
                      :link="{name: 'Access Management', icon: 'nc-icon nc-circle-09'}">

          <sidebar-item v-if="$store.getters['auth/checkAccess']('roles/index')"
                        :link="{name: 'Roles', path: '/roles/list'}"></sidebar-item>
          <sidebar-item v-if="false"
                        :link="{name: 'Permissions', path: '/permissions/list'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/checkAccess']('users/index')"
                        :link="{name: 'Users', path: '/users/list'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/checkAccess']('users/get-deleted-users')"
                        :link="{name: 'Deleted Users', path: '/users/DeletedUsersList'}"></sidebar-item>

        </sidebar-item>





      </template>

    </side-bar>


    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"></dashboard-content>

      <content-footer></content-footer>
    </div>

  </div>
</template>
<script>
import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import DashboardContent from './Content.vue'
import MobileMenu from './Extra/MobileMenu.vue'
import UserMenu from './Extra/UserMenu.vue'


export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    UserMenu
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    }
  }
}

</script>
